<template>
  <div class="container">
    <div class="header">
      <p>AI 백선생</p>
      <v-icon @click="copyLink">mdi-export-variant</v-icon>
    </div>
    <div class="contents">
      <h2 class="title">매주 분석 받아보기</h2>
      <p class="description">
        연락처를 입력하고 매주 분석을 <br />카카오톡으로 받아보세요.
      </p>
      <input v-model="phoneNumber" type="text" @keyup.enter="submitLink" />
    </div>
    <div class="footer">
      <Button label="매주 분석 받아보기" @click="submit" />
    </div>
    <ErrorToast v-if="showError" message="핸드폰 번호를 확인해주세요." />
    <div
      v-if="isURLCopyNotice"
      style="
        position: fixed;
        border-radius: 8px;
        bottom: 30%;
        color: #fff;
        background: rgba(32, 32, 32, 0.4);
        padding: 8px 12px;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      "
    >
      링크를 복사가 완료되었습니다.
    </div>
  </div>
</template>

<script>
import ErrorToast from '../../components/ai/ErrorToast.vue';
import Button from '../../components/ai/Button.vue';

export default {
  components: {
    ErrorToast,
    Button,
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText('https://supermembers.co.kr/aibaek/');
    },
    async submit() {
      const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      const id = this.$route.params.id;

      if (!regPhone.test(this.phoneNumber)) {
        this.showError = true;

        setTimeout(() => {
          this.showError = false;
        }, 2000);

        return;
      }

      try {
        await this.axios.get(
          `/ai/review/report/send?contact=${this.phoneNumber}&id=${id}`
        );

        this.$router.push({ path: `/ai/analysis-result` });
      } catch (error) {
        console.log(error);
      }
    },
  },
  data() {
    return {
      phoneNumber: '',
      showError: false,
      isURLCopyNotice: false,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
}

.header {
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  p {
    color: #333;
    text-align: center;
    /* subline-bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }

  button {
    color: #333;
    position: absolute;
    right: 16px;
  }
}

.footer {
  height: 85px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  border-top: 1px solid rgba(204, 204, 204, 1);
}

.contents {
  flex: 1;
  padding: 0px 16px;

  .title {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    margin-bottom: 4px;
  }

  .description {
    color: #333;
    /* body-medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 16px;
  }

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #fff;
    box-sizing: border-box;
    outline: none;
    height: 45px;
    width: 100%;
  }
}
</style>
