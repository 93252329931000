<template>
  <transition name="fade">
    <div class="error-toast">
      <p>{{ message }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['message'],
};
</script>

<style scoped>
.error-toast {
  background-color: rgba(234, 42, 58, 0.9);
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  position: fixed;
  bottom: 20px;
  left: 16px;
  right: 16px;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
